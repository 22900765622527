import fetch from 'isomorphic-unfetch';
import router from 'next/router';
import { apiUrls } from '../config';
import type { FetchNewProps } from '../containers/Settings/News/index.types';
import type { EmailPayload } from '../redux/emails/types';
import type { NewsPayload, NewsUpdatePayload } from '../redux/settings/news/types';
import type {
  NotificationsPayload,
  PostNotificacaoBody,
} from '../redux/settings/notifications/types';
import type {
  Options,
  PartnerOptions,
  PlanosEGruposPayload,
  PlansGroupsOptions,
  ReenviarConviteOptions,
} from '../redux/types';
import store from '../store/store';
import appActions from '../redux/app/actions';
import type { TransformPayload } from '../redux/users/types';
import type { PostIndicacoesPayload } from '../store/perfil/indicacoes/indicacoesSlice.types';
import type {
  DeleteMethod,
  GetMethod,
  PatchEditarParceiro,
  PatchMethod,
  PostMethod,
  PutMethod,
} from './api.types';
import { getCookie, removeCookie } from './session';
import moment from 'moment';
import { InserirClassificacaoFiscalData } from 'containers/Profile/modalContent/classificacaoFiscal/cadastroClassificacaoFiscal/cadastroClassificacaoFiscal.types';

function makeHeaders() {
  let headers = {};

  if (typeof getCookie('id_token') === 'undefined') {
    store.dispatch({ type: appActions.IS_LOADING, payload: false });
    router.push('/signin');
    throw Error('NO TOKEN OR A COOKIE FOR THE REQUEST TO BE MADE');
  } else {
    return (headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCookie('id_token')}`,
    });
  }
}

export const parseOptions = (options: any) => {
  let params = '';
  if (options)
    Object.keys(options).map((option, index) => {
      const value = Object.values(options)[index];
      if (value != null || value != undefined) {
        params += `${index !== 0 ? '&' : ''}${option}=${value}`;
      }
    });
  return params;
};

const parseResponse = async (res: Response) => {
  let json;

  try {
    json = await res.json();
  } catch (error) {}

  if (res.ok) {
    if (json) {
      return json;
    }
    return res.status;
  }

  if (res.status === 401 || json?.authErrors) {
    removeCookie(getCookie('id_token'));
    router.push('/login');
  }

  throw json;
};

const parseErrors = async (err: Error) => {
  if (err) return err;
};

class Api {
  login = async (userInfo: any) => {
    return await fetch(apiUrls.login, {
      method: 'post',
      body: JSON.stringify(userInfo),
    }).then(parseResponse);
  };
  logout = async () => {
    return await fetch(apiUrls.logout, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  getStates = async () => {
    return await fetch(apiUrls.states, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  getCities = async (payload: any): Promise<Response> => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.cities}?${params}`, {
      //return await fetch(`${apiUrls.cities}/${payload}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };

  getCitiesAutoFill = async (options: { search: string }) => {
    const params = parseOptions(options);
    return await fetch(`${apiUrls.cities}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  getCep = async (cep: string): Promise<Response> => {
    return await fetch(`${apiUrls.cep}/${cep}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchCNPJ = async (cnpj: string) => {
    return await fetch(`${apiUrls.cnpj}/${cnpj}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchDefinitions = async () => {
    return await fetch(apiUrls.definitions, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  updateDefinitions = async (payload: any) => {
    return await fetch(apiUrls.definitions, {
      method: 'put',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    } as PutMethod).then(parseResponse);
  };
  fetchEmail = async (email: string) => {
    return await fetch(`${apiUrls.verifyEmail}?email=${email}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  getCNPJ = async (cnpj: string) => {
    return await fetch(`${apiUrls.verifyCnpj}/${cnpj}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };

  // -------------------------------------------------------------------------------------------------

  googleAuth = async (): Promise<Response> => {
    return await fetch(apiUrls.googleAuth, {
      method: 'get',
    } as GetMethod).then(parseResponse);
  };
  googleRedirect = async (payload: any): Promise<Response> => {
    return await fetch(`${apiUrls.googleAuthCallback}?code=${payload}`, {
      method: 'get',
    } as GetMethod).then(parseResponse);
  };
  googleGetData = async (token: string): Promise<Response> => {
    return await fetch(`${apiUrls.googleGetTokenData}/${token}`, {
      method: 'get',
    } as GetMethod).then(parseResponse);
  };

  // fetchGaps = async () => {
  //   return await fetch(apiUrls.gaps, {
  //     method: 'get',
  //     headers: makeHeaders(),
  //   }).then(parseResponse);
  // };
  fetchErrors = async (payload: any): Promise<Response> => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.errors}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  postComment = async (payload: any) => {
    return await fetch(apiUrls.comments, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  deleteComment = async (payload: any) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.comments}?${params}`, {
      method: 'delete',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as DeleteMethod).then(parseResponse);
  };
  fetchResults = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.results}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchUpsell = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.upsell}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchUpsellRecursos = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.upsellRecursos}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchDownsell = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.downsell}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchDownsellRecursos = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.downsellRecursos}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchChurn = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.churn}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchChurnReason = async () => {
    return await fetch(apiUrls.churnReason, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchIncome = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.income}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchExempt = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.exempt}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchNewClients = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.newClients}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchNPS = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.nps}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchIndications = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.indications}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  convertIndication = async (
    id: number,
    payload: { codigoEmpresaIndicada: number }
  ) => {
    return await fetch(`${apiUrls.indication}/${id}`, {
      method: 'PATCH',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PatchMethod).then(parseResponse);
  };
  fetchProfileIndications = async (id: number, payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.indication}/${id}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  postIndications = async (payload: PostIndicacoesPayload) => {
    return await fetch(apiUrls.indication, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };
  deleteIndication = async (id: number) => {
    return await fetch(`${apiUrls.indication}/${id}`, {
      method: 'delete',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchGoals = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.goals}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  insertGoal = async (payload: Options) => {
    return await fetch(apiUrls.goals, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };
  updateGoal = async (id: number, payload: any) => {
    return await fetch(`${apiUrls.goals}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then(parseResponse);
  };
  fetchActivePlans = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.activePlansList}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchPlans = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.plan}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchPlan = async (id: number) => {
    return await fetch(`${apiUrls.plan}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchHistoricoPlanos = async (id: number, payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.clients}/${id}/historico-planos?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  insertPlan = async (payload: any) => {
    return await fetch(apiUrls.plan, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  updatePlan = async (id: number, payload: any, callback: () => any) => {
    return await fetch(`${apiUrls.plan}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then((res) => {
      if (res.status === 204) {
        callback();
      }
      return parseResponse(res);
    });
  };
  deletePlan = async (id: number) => {
    return await fetch(`${apiUrls.plan}/${id}`, {
      method: 'delete',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  createPlanShareLink = async (id: number) => {
    return await fetch(`${apiUrls.plan}/${id}/link`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  sendEmailPlanLink = async (
    id: number,
    payload: { para: string[]; assunto: string; corpo: string }
  ) => {
    return await fetch(`${apiUrls.plan}/${id}/link`, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchGroupPlansList = async (id: number) => {
    return await fetch(`${apiUrls.groups}/${id}/planos`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  unlinkPlan = async (payload: { primeiroid: number; planoid: number }) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.unlinkPlan}?${params}`, {
      method: 'delete',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  setPlanSituation = async (id: number, situation: 0 | 1) => {
    return await fetch(`${apiUrls.plan}/${id}/${situation}`, {
      method: 'delete',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchResources = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.resources}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchResource = async (id: number) => {
    return await fetch(`${apiUrls.resources}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  insertResource = async (payload: {
    codigo: number;
    descricao: string;
    nome: string;
    slug: string;
  }) => {
    return await fetch(`${apiUrls.resources}`, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  updateResource = async (
    id: number,
    payload: {
      codigo: number;
      descricao: string;
      nome: string;
      slug: string;
    }
  ) => {
    return await fetch(`${apiUrls.resources}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then(parseResponse);
  };
  deleteResource = async (id: number) => {
    return await fetch(`${apiUrls.resources}/${id}`, {
      method: 'delete',
      headers: makeHeaders(),
    } as DeleteMethod).then(parseResponse);
  };
  inactivateActivateResource = async (id: number, status: 0 | 1) => {
    return await fetch(`${apiUrls.resources}/${id}/${status}`, {
      method: 'delete',
      headers: makeHeaders(),
    } as DeleteMethod).then(parseResponse);
  };
  fetchGroups = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.groups}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchGroup = async (payload: any) => {
    return await fetch(`${apiUrls.groups}/${payload}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  insertGroup = async (payload: any) => {
    return await fetch(apiUrls.groups, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  updateGroup = async (id: number, payload: any) => {
    return await fetch(`${apiUrls.groups}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    }).then(parseResponse);
  };
  deleteGroup = async (ids: number | number[]) => {
    return await fetch(`${apiUrls.groups}/${ids}`, {
      method: 'delete',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  inactivateGroup = async (ids: number | number[], inactivatePlans: 0 | 1) => {
    const params = parseOptions({ 'inativar-planos': inactivatePlans });
    return await fetch(`${apiUrls.groups}/${ids}/inativar?${params}`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  activateGroup = async (ids: number | number[]) => {
    return await fetch(`${apiUrls.groups}/${ids}/ativar`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  createGroupShareLink = async (id: number) => {
    return await fetch(`${apiUrls.groups}/${id}/link`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchPartnersTypes = async (payload: Options & { status?: number }) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.partnersTypes}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchPartnerType = async (payload: any) => {
    return await fetch(`${apiUrls.partnersTypes}/${payload}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  insertPartnerType = async (payload: any) => {
    return await fetch(apiUrls.partnersTypes, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  updatePartnerType = async (id: number, payload: any) => {
    return await fetch(`${apiUrls.partnersTypes}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then(parseResponse);
  };
  deletePartnerType = async (ids: number | number[]) => {
    return await fetch(apiUrls.partnersTypes, {
      method: 'delete',
      body: JSON.stringify({ ids: ids }),
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  inactivatePartnerType = async (id: number) => {
    return await fetch(`${apiUrls.partnersTypes}/${id}/inativar`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  activatePartnerType = async (id: any) => {
    return await fetch(`${apiUrls.partnersTypes}/${id}/ativar`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  verifyPartnerType = async (id: number) => {
    return await fetch(`${apiUrls.partnersTypes}/${id}/verificar-uso`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  transferPartnerType = async (payload: {
    codigo_tipo_parceiro: number;
    empresas: number[];
  }) => {
    return await fetch(`${apiUrls.partnersTypes}/transferir`, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  invitePartner = async (payload: string | number) => {
    return await fetch(apiUrls.invitePartner, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  fetchPartners = async (payload: PartnerOptions) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.clients}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  postReenviarConvite = async (id: number, payload: ReenviarConviteOptions) => {
    return await fetch(`${apiUrls.clients}/${id}/reenviar-convite`, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  fetchUsers = async (id: number, payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.clients}/${id}/usuarios?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchPartner = async (id: any) => {
    return await fetch(`${apiUrls.clients}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  insertPartner = async (payload: any) => {
    return await fetch(apiUrls.clients, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  patchPartner = async (id: number, payload: any) => {
    return await fetch(`${apiUrls.clients}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  trocarResponsavel = async (codigoPartner: number, codigoUsuario: number) => {
    return await fetch(`${apiUrls.clients}/${codigoPartner}/trocar-responsavel`, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify({ codigoUsuario }),
    } as PostMethod).then(parseResponse);
  };
  trocarCnpj = async (
    codigoPartner: number,
    data: { cpfCnpj: number; tipoPessoa: 0 | 1 }
  ) => {
    return await fetch(`${apiUrls.clients}/${codigoPartner}/trocar-cnpj`, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(data),
    } as PostMethod).then(parseResponse);
  };
  changePartnerPlan = async (
    id: number,
    payload: {
      codigoPlano: number;
      codigoPlanoValor: number;
      descricaoPlano: string;
      diaVencimentoBoleto: number;
      diasCarencia: number;
      valorPlano: number;
    }
  ) => {
    return await fetch(`${apiUrls.clients}/${id}/alterar-plano`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  inactivateClient = async (payload: any) => {
    return await fetch(apiUrls.inactivateClient, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  fetchMeuPlano = async (id: number) => {
    return await fetch(`${apiUrls.clients}/${id}/meu-plano`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  //TODO: juntar esse active com o unblockpartner pq é o mesmo endpoint
  activatePartner = async (id: any) => {
    const payload = { codigo: id, tipoAtivacao: 0 };
    return await fetch(`${apiUrls.activatePartner}`, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  fetchEmails = async (payload: any) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.emails}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  getEmail = async (id: number) => {
    return await fetch(`${apiUrls.emails}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  sendEmail = async (payload: EmailPayload) => {
    return await fetch(apiUrls.emails, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    } as PostMethod).then(parseResponse);
  };
  deleteEmail = async (id: number) => {
    return await fetch(`${apiUrls.emails}/${id}`, {
      method: 'delete',
      headers: makeHeaders(),
    } as DeleteMethod).then(parseResponse);
  };
  forceSendEmail = async (id: number) => {
    return await fetch(`${apiUrls.emails}/${id}/forcar-envio`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  findClients = async (payload?: any) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.findClients}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  linkClients = async (id: number, payload: { ids_empresas: number[] }) => {
    return await fetch(`${apiUrls.clients}/${id}/associar-empresas`, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  unlinkClient = async (id: number) => {
    return await fetch(`${apiUrls.clients}/${id}/desvincular`, {
      method: 'PATCH',
      body: JSON.stringify(id),
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  transferClient = async (clientId: number, destinoId: number) => {
    return await fetch(`${apiUrls.clients}/${clientId}/transferir/${destinoId}`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  unblockPartner = async (payload: {
    codigo: number;
    /**0: ativa apenas a empresa, 1: ativa empresa e seus clientes */
    tipoAtivacao: 0 | 1;
  }) => {
    return await fetch(`${apiUrls.unblockPartner}`, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  blockPartner = async (payload: {
    codigo: number;
    motivoBloqueio?: string;
    tipoBloqueio: number;
  }) => {
    return await fetch(`${apiUrls.blockPartner}`, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  exemptCharge = async (payload: any) => {
    return await fetch(apiUrls.exemptCharge, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  undoExemptCharge = async (payload: any) => {
    return await fetch(apiUrls.undoExemptCharge, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  markAsPayd = async (payload: any) => {
    return await fetch(apiUrls.exemptCharge, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  fetchCharges = async (id: any, payload?: any) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.charges}/${id}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  consultarBoleto = async (id: number) => {
    return await fetch(`${apiUrls.charges}/${id}/consultar`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchDownloadCharge = async (id: any) => {
    return await fetch(`${apiUrls.downloadCharge}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchDownloadNfe = async (id: any) => {
    return await fetch(`${apiUrls.donwloadNfe}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  postNewCharge = async (payload: any) => {
    return await fetch(apiUrls.newCharge, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  postSeparateCharge = async (payload: any) => {
    return await fetch(apiUrls.separateCharge, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  fetchPartnerExperience = async (id: number, payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.clients}/${id}/experiencia?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchPartnerHistoric = async (id: number, payload?: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.clients}/${id}/historico?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  fetchDashboard = async (payload?: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.dashboard}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchGraphics = async (payload?: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.graphic}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  postDeposition = async (payload: {
    codigo_empresa: number;
    data: string;
    depoimento: string;
    nome: string;
  }) => {
    return await fetch(apiUrls.deposition, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  fetchDepositions = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.deposition}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchDeposition = async (id: number) => {
    return await fetch(`${apiUrls.deposition}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  updateDeposition = async (
    id: number,
    payload: {
      codigo_empresa: number;
      data: string;
      depoimento: string;
      nome: string;
    }
  ) => {
    return await fetch(`${apiUrls.deposition}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then(parseResponse);
  };
  deleteDeposition = async (id: number) => {
    return await fetch(`${apiUrls.deposition}/${id}`, {
      method: 'delete',
      headers: makeHeaders(),
    } as DeleteMethod).then(parseResponse);
  };
  fetchNews = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.news}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchNew = async (id: number): Promise<FetchNewProps> => {
    return await fetch(`${apiUrls.news}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  insertNews = async (payload: NewsPayload) => {
    return await fetch(`${apiUrls.news}`, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  updateNews = async (id: number, payload: NewsUpdatePayload) => {
    return await fetch(`${apiUrls.news}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then(parseResponse);
  };
  deleteNews = async (id: number) => {
    return await fetch(`${apiUrls.news}/${id}`, {
      method: 'delete',
      headers: makeHeaders(),
    } as DeleteMethod).then(parseResponse);
  };
  fetchSetup = async () => {
    return await fetch(apiUrls.setup, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  updateSetup = async (payload: any) => {
    return await fetch(apiUrls.setup, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then(parseResponse);
  };
  fetchUsersAccess = async (payload: any) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.setup}/usuarios?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  blockUserAccess = async (id: number) => {
    return await fetch(`${apiUrls.setup}/usuarios/${id}/bloquear-acesso`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  allowUserAccess = async (id: number) => {
    return await fetch(`${apiUrls.setup}/usuarios/${id}/permitir-acesso`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  fetchPlansGroups = async (payload: PlansGroupsOptions) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.plansGroups}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  transformPartnerClient = async (id: number, payload: TransformPayload) => {
    return await fetch(`${apiUrls.clients}/${id}/transformar`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  fetchObservations = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.observation}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  postObservation = async (payload: { empresa: number; descricao: string }) => {
    return await fetch(apiUrls.observation, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  updateObservation = async (
    id: number,
    payload: {
      descricao: string;
    }
  ) => {
    return await fetch(`${apiUrls.observation}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then(parseResponse);
  };
  deleteObservation = async (id: number) => {
    return await fetch(`${apiUrls.observation}/${id}`, {
      method: 'delete',
      headers: makeHeaders(),
    } as DeleteMethod).then(parseResponse);
  };
  fetchNotifications = async (payload: Options) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.notifications}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  fetchNotification = async (id: number) => {
    return await fetch(`${apiUrls.notifications}/${id}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  insertNotification = async (payload: PostNotificacaoBody) => {
    return await fetch(`${apiUrls.notifications}`, {
      method: 'post',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PostMethod).then(parseResponse);
  };
  updateNotification = async (id: number, payload: NotificationsPayload) => {
    return await fetch(`${apiUrls.notifications}/${id}`, {
      method: 'put',
      headers: makeHeaders(),
      body: JSON.stringify(payload),
    } as PutMethod).then(parseResponse);
  };
  deleteNotification = async (id: number) => {
    return await fetch(`${apiUrls.notifications}/${id}`, {
      method: 'delete',
      headers: makeHeaders(),
    } as DeleteMethod).then(parseResponse);
  };
  endNotification = async (id: number) => {
    return await fetch(`${apiUrls.notifications}/${id}/finalizar`, {
      method: 'PATCH',
      headers: makeHeaders(),
    } as PatchMethod).then(parseResponse);
  };
  patchAdicionarRecursoPlano = async (
    idUsuario: number,
    idRecurso: number,
    valorRecurso: number
  ) => {
    return await fetch(
      `${apiUrls.clients}/${idUsuario}/adicionar-recurso/${idRecurso}`,
      {
        method: 'PATCH',
        headers: makeHeaders(),
        body: JSON.stringify({ valorRecurso }),
      } as PatchMethod
    ).then(parseResponse);
  };
  patchRemoverRecursoPlano = async (
    idUsuario: number,
    idRecurso: number,
    imediatamente: 0 | 1
  ) => {
    return await fetch(
      `${apiUrls.clients}/${idUsuario}/remover-recurso/${idRecurso}/${imediatamente}`,
      {
        method: 'PATCH',
        headers: makeHeaders(),
      } as PatchMethod
    ).then(parseResponse);
  };
  fetchPlanosEGrupos = async (payload: PlanosEGruposPayload) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.planosEGrupos}?${params}`, {
      method: 'get',
      headers: makeHeaders(),
    } as GetMethod).then(parseResponse);
  };
  patchEditarParceiro = async (id: number, body: PatchEditarParceiro) => {
    return await fetch(`${apiUrls.clients}/${id}/editar-parceiro`, {
      method: 'PATCH',
      headers: makeHeaders(),
      body: JSON.stringify(body),
    } as PatchMethod).then(parseResponse);
  };
  buscarErrosResumidos = async ({
    dataInicial,
    dataFinal,
  }: {
    dataInicial: string;
    dataFinal: string;
  }) => {
    const _params = parseOptions({
      data_inicial: `${moment(dataInicial).format('YYYY-MM-DD')}T00:00:00-03:00`,
      data_final: `${moment(dataFinal).format('YYYY-MM-DD')}T23:59:59-03:00`,
    });
    const _query = _params.length > 0 ? `?${_params}` : '';
    const _raw = await fetch(`${apiUrls.errosResumidos}${_query}`, {
      method: 'GET',
      headers: makeHeaders(),
    });
    const _res = await _raw.json();
    return _res;
  };
  buscarRecursos = async (query: {
    search?: string;
    all?: boolean;
    page?: number;
    pageSize?: number;
    situacao?: number;
  }) => {
    const params = parseOptions(query);
    return await fetch(`${apiUrls.resources}?${params}`, {
      method: 'GET',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  gerarCobrancaPrePaga = async (payload: {
    sistema: number;
    empresa: number;
    data_inicial: string;
  }) => {
    const params = parseOptions(payload);
    return await fetch(`${apiUrls.cobrancaPrePaga}?${params}`, {
      method: 'POST',
      headers: makeHeaders(),
    }).then(parseResponse);
  };
  solicitarAcessoUsuario = async (query: { codigoCliente: number }) => {
    const _raw = await fetch(
      `${apiUrls.clients}/${query.codigoCliente}/solicitar-acesso`,
      {
        method: 'GET',
        headers: makeHeaders(),
      }
    );
    const _res = await _raw.json();

    return _res;
  };
  fetchClassificacoesFiscais = async (codigoCliente: number, options: Options) => {
    const params = parseOptions(options);

    return await fetch(
      `${apiUrls.empresas}/${codigoCliente}/classificacoes-fiscais?${params}`,
      {
        method: 'get',
        headers: makeHeaders(),
      } as GetMethod
    ).then(parseResponse);
  };
  fetchClassificacaoFiscal = async (codigoCliente: number, codigo: number) => {
    return await fetch(
      `${apiUrls.empresas}/${codigoCliente}/classificacoes-fiscais/${codigo}`,
      {
        method: 'get',
        headers: makeHeaders(),
      } as GetMethod
    ).then(parseResponse);
  };
  inserirClassificacaoFiscal = async (
    codigoCliente: number,
    data: InserirClassificacaoFiscalData
  ) => {
    return await fetch(
      `${apiUrls.empresas}/${codigoCliente}/classificacoes-fiscais`,
      {
        method: 'post',
        headers: makeHeaders(),
        body: JSON.stringify(data),
      } as PostMethod
    ).then(parseResponse);
  };
  editarClassificacaoFiscal = async (
    codigoCliente: number,
    codigo: number,
    data: InserirClassificacaoFiscalData
  ) => {
    return await fetch(
      `${apiUrls.empresas}/${codigoCliente}/classificacoes-fiscais/${codigo}`,
      {
        method: 'put',
        headers: makeHeaders(),
        body: JSON.stringify(data),
      } as PutMethod
    ).then(parseResponse);
  };
  inativarClassificacaoFiscal = async (codigoCliente: number, codigo: number) => {
    return await fetch(
      `${apiUrls.empresas}/${codigoCliente}/classificacoes-fiscais/${codigo}`,
      {
        method: 'delete',
        headers: makeHeaders(),
      } as DeleteMethod
    ).then(parseResponse);
  };
}

export default new Api();
